import React, { type ReactNode } from 'react';
import styles from './styles/Navbar.module.scss';
import { NavbarHelperIcon } from '../../icons/NavbarHelperIcon';
import { ClickAwayListener } from '../../hooks/ClickAwayListener';
import { Popper } from '@gr/ui';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { NavbarAccountIcon } from '../../icons/NavbarAccountIcon';
import { OffIcon } from '../../icons/OffIcon';
import { Path } from './constants/paths';
import useSession from '../../hooks/useSession';
import { Box, Button, Flex, SuccessSmallIcon, Text } from '@gr/ui-neo';

export function NavbarControls(): JSX.Element {
    const [isNavbarHelperOpen, setIsNavbarHelperOpen] = React.useState<boolean>(null);
    const navbarHelperContainer = React.useRef(null);
    const [isNavbarAccountOpen, setIsNavbarAccountOpen] = React.useState<boolean>(null);
    const navbarAccountContainer = React.useRef(null);
    const { session, signOut } = useSession();
    const assignedToRecurring = session?.assignedToRecurring === 'yes';
    const assignedToBounty = session?.assignedToBounty === 'yes';
    const isAgency = session?.isAgency === 'yes';

    const handleHelperClick = React.useCallback((): void => {
        setIsNavbarHelperOpen((prev) => !prev);
    }, []);

    const handleHelperClickAway = React.useCallback((): void => {
        setIsNavbarHelperOpen(false);
    }, []);

    const handleAccountClick = React.useCallback((): void => {
        setIsNavbarAccountOpen((prev) => !prev);
    }, []);

    const handleAccountClickAway = React.useCallback((): void => {
        setIsNavbarAccountOpen(false);
    }, []);

    const handleJoinClick = React.useCallback((): void => {
        setIsNavbarAccountOpen(false);
    }, []);

    const helperOpenClass = isNavbarHelperOpen ? styles.open : '';
    const accountOpenClass = isNavbarAccountOpen ? styles.open : '';

    const accountInfoElement = (programName: string, path: string, assigned: boolean): JSX.Element => (
        <Flex flexDirection="column" alignItems="center" gap="10px">
            <Box>
                <FormattedMessage
                    id={`Navbar.Account.${programName}Program`}
                    values={{
                        'color-sea': (...chunks: ReactNode[]) => (
                            <Text color="sea" tag="span">
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Box>
            {assigned ? (
                <Flex gap="10px" minHeight="38px" alignItems="center">
                    <SuccessSmallIcon/>
                    <Text color="rgb(0, 198, 98)" variant="body-medium-bold">
                        <FormattedMessage id="Navbar.Account.SignedUp"/>
                    </Text>
                </Flex>
            ) : (
                <Link href={path} onClick={handleJoinClick}>
                    <Button size="small" variant="upgrade" border="none">
                        <FormattedMessage id="Navbar.Account.Join"/>
                    </Button>
                </Link>
            )}
        </Flex>
    );

    return (
        <React.Fragment>
            <div
                onClick={handleHelperClick}
                role="button"
                tabIndex={0}
                className={`${styles.navigationControlsHelper} ${helperOpenClass}`}
                ref={navbarHelperContainer}
            >
                <NavbarHelperIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleHelperClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarHelperOpen}
                    anchorEl={navbarHelperContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowHelper}`}
                >
                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.HelpCenter}>
                            <FormattedMessage id="Navbar.Helper.HelpCenter"/>
                        </Link>
                    </div>

                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.MyInquiries}>
                            <FormattedMessage id="Navbar.Helper.MyInquiries"/>
                        </Link>
                    </div>

                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.Library}>
                            <FormattedMessage id="Navbar.Helper.Library"/>
                        </Link>
                    </div>
                </Popper>
            </ClickAwayListener>
            <div
                onClick={handleAccountClick}
                role="button"
                tabIndex={0}
                ref={navbarAccountContainer}
                className={`${styles.navigationControlsAccount} ${accountOpenClass}`}
            >
                <NavbarAccountIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleAccountClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarAccountOpen}
                    anchorEl={navbarAccountContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowAccount}`}
                >
                    <Flex gap="20px">
                        {isAgency === false ?
                            <Flex background="light" borderRadius="8px" margin="5px" padding="15px" color="#202730" gap="20px">
                                {accountInfoElement('Recurring', Path.ARP, assignedToRecurring)}
                                <Box width="1px" borderRight="1px solid #D3D5D9"/>
                                {accountInfoElement('Bounty', Path.ABP, assignedToBounty)}
                            </Flex> : null}
                        <Flex flexDirection="column" justifyContent="center" gap="10px">
                            <div role="button"
                                tabIndex={0}
                                className={styles.navigationControlsPopperAccountLink}
                                onClick={handleAccountClickAway}
                            >
                                <Link href={Path.MyAccount}>
                                    <FormattedMessage id="Navbar.Account.MyAccount"/>
                                </Link>
                            </div>
                            <div className={styles.navigationControlsPopperDivider}/>
                            <div className={styles.navigationControlsPopperSignOutLink} onClick={signOut}>
                                <button type="button">
                                    <FormattedMessage id="Navbar.Account.SignOut"/>
                                </button>
                                <OffIcon/>
                            </div>
                        </Flex>
                    </Flex>
                </Popper>
            </ClickAwayListener>
        </React.Fragment>
    );
}
