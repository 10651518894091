import React, { type ReactNode } from 'react';
import styles from './styles/Navbar.module.scss';
import { Popper } from '@gr/ui';
import { ClickAwayListener } from '../../hooks/ClickAwayListener';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { HamburgerIcon } from '../../icons/HamburgerIcon';
import { OffIcon } from '../../icons/OffIcon';
import { Path } from './constants/paths';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';
import { Box, Button, Flex, SuccessSmallIcon, Text } from '@gr/ui-neo';

export function NavbarMobile(): JSX.Element {
    const [isNavbarHamburgerOpen, setIsNavbarHamburgerOpen] = React.useState<boolean>(null);
    const navbarHamburgerContainer = React.useRef(null);
    const router = useRouter();
    const { session, signOut } = useSession();
    const isInZeroState = session?.assignedToBounty === 'no' && session?.assignedToRecurring === 'no';
    const assignedToRecurring = session?.assignedToRecurring === 'yes';
    const assignedToBounty = session?.assignedToBounty === 'yes';
    const isAgency = session?.isAgency === 'yes';

    const handleHelperClick = React.useCallback((): void => {
        setIsNavbarHamburgerOpen((prev) => !prev);
    }, []);

    const handleHelperClickAway = React.useCallback((): void => {
        setIsNavbarHamburgerOpen(false);
    }, []);

    const hamburgerOpenClass = isNavbarHamburgerOpen ? styles.open : '';
    const isPageActive = (path: Path): string => (path === router.pathname ? styles.mobileActive : '');

    const promotionalMaterialsLink = (): JSX.Element => {
        if (isInZeroState) { return null; }
        if (localStorage.getItem('newMaterials') !== 'true') { return null; }

        return (
            <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.PromotionalMaterials)}`}>
                <Link href={Path.PromotionalMaterials}>
                    <FormattedMessage id="Navbar.Links.PromotionalMaterials"/>
                </Link>
            </div>
        );
    };

    const bountyProgramLink = (): JSX.Element => {
        if (localStorage.getItem('newMaterials') === 'true') { return null; }
        if (isAgency) { return null; }

        return (
            <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.ABP)}`}>
                <Link href={Path.ABP}>
                    <FormattedMessage id="Navbar.Links.AbpProgram"/>
                </Link>
            </div>
        );
    };

    const recurringProgramLink = (): JSX.Element => {
        if (localStorage.getItem('newMaterials') === 'true') { return null; }

        return (
            <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.ARP)}`}>
                <Link href={Path.ARP}>
                    <FormattedMessage id="Navbar.Links.IapProgram"/>
                </Link>
            </div>
        );
    };

    const accountInfoElement = (programName: string, path: string, assigned: boolean): JSX.Element => (
        <Flex flexDirection="column" gap="10px" alignItems="center">
            <Box>
                <FormattedMessage
                    id={`Navbar.Account.${programName}Program`}
                    values={{
                        'color-sea': (...chunks: ReactNode[]) => (
                            <Text color="sea" tag="span">
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Box>
            {assigned ? (
                <Flex gap="10px" alignItems="center">
                    <SuccessSmallIcon/>
                    <Text color="rgb(0, 198, 98)" variant="body-medium-bold">
                        <FormattedMessage id="Navbar.Account.SignedUp"/>
                    </Text>
                </Flex>
            ) : (
                <Link href={path}>
                    <Button size="small" variant="upgrade" border="none">
                        <FormattedMessage id="Navbar.Account.Join"/>
                    </Button>
                </Link>
            )}
        </Flex>
    );

    return (
        <React.Fragment>
            <div
                onClick={handleHelperClick}
                role="button"
                tabIndex={0}
                className={`${styles.navigationControlsHamburger} ${hamburgerOpenClass}`}
                ref={navbarHamburgerContainer}
            >
                <HamburgerIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleHelperClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarHamburgerOpen}
                    anchorEl={navbarHamburgerContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowHamburger}`}
                >
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.Dashboard)}`}>
                        <Link href={Path.Dashboard}>
                            <FormattedMessage id="Navbar.Links.Dashboard"/>
                        </Link>
                    </div>

                    {promotionalMaterialsLink()}
                    {bountyProgramLink()}
                    {recurringProgramLink()}

                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.HelpCenter)}`}>
                        <Link href={Path.HelpCenter}>
                            <FormattedMessage id="Navbar.Helper.HelpCenter"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.Library)}`}>
                        <Link href={Path.Library}>
                            <FormattedMessage id="Navbar.Helper.Library"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.MyInquiries)}`}>
                        <Link href={Path.MyInquiries}>
                            <FormattedMessage id="Navbar.Helper.MyInquiries"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.MyAccount)}`}>
                        <Link href={Path.MyAccount}>
                            <FormattedMessage id="Navbar.Account.MyAccount"/>
                        </Link>
                    </div>
                    {isAgency === false ?
                        <Flex flexDirection="column"
                            background="light"
                            borderRadius="8px"
                            padding="15px"
                            color="#202730"
                            gap="10px"
                            marginBottom="10px"
                        >
                            {accountInfoElement('Recurring', Path.ARP, assignedToRecurring)}
                            <Box borderTop="1px solid #D3D5D9"/>
                            {accountInfoElement('Bounty', Path.ABP, assignedToBounty)}
                        </Flex> : null}
                    <div className={styles.navigationControlsPopperDivider}/>
                    <div className={styles.navigationControlsPopperSignOutLink}>
                        <button type="button" onClick={() => signOut()}>
                            <FormattedMessage id="Navbar.Account.SignOut"/>
                        </button>
                        <OffIcon/>
                    </div>
                </Popper>
            </ClickAwayListener>
        </React.Fragment>
    );
}
